function sectionPartners() {
    const section = document.querySelector('.section-partners');
    if( !section ) return;
    
    window.addEventListener('load', () => {
        const $slider = $('.partners-slider-wrap');
        $slider.find('.partners-slider').slick({
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 1,
            prevArrow: $slider.find('.partners-slider-arrows__arrow.prev'),
            nextArrow: $slider.find('.partners-slider-arrows__arrow.next'),
            dots: section.classList.contains('section-partners--one'),
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 3,
                    }
                }
            ]
        })
    })
    
    
    
    function partnersCollapse() {
        const blocks = document.querySelectorAll('.partners-collapse');
    
        blocks.forEach(block => {
            const button = block.querySelector('.partners-show-more');
            let buttonText = button.querySelector('.partners-show-more__text');
            const hiddenBlock = block.querySelector('.partners-hidden-block');
    
            const toggleCollapse = event => {
                event.preventDefault();
    
                button.classList.toggle('active');
                $(hiddenBlock).stop().slideToggle('fast');
    
                buttonText.innerText = button.classList.contains('active') ? 'Скрыть' : 'Показать еще';
            }
    
            button.addEventListener('click', toggleCollapse);
        });
    }
    partnersCollapse();
}

sectionPartners();