const formLoading = form => {
	form.classList.add('loading');
}

const formLoadingEnd = form => {
	form.classList.remove('loading');
}

const handlerSubmit = async event => {
    event.preventDefault();
    const form = event.target;
    const successText = form.dataset.success ? form.dataset.success : 'Сообщение успешно отправленно';
    const successHTML = `<div class="form-success"><svg role="image" class="form-success__svg"><use xlink:href="${ templateUrl }/static/images/sprite.svg#success" /></svg><p class="form-success__text">${ successText }</p></div>`;
    const initialHTML = form.innerHTML;
    const initialHeight = form.clientHeight;

    formLoading(form);
    form.querySelectorAll('.field').forEach(el => el.classList.remove('error'));

    const formData = new FormData(form);
    formData.append('action', form.dataset.action);

    const data  = await fetch('/feadback/ajax.php', { method: 'POST', body: formData});

    if( data.valid == 'not-valid' ) {
        console.error( 'not valid' )
        for(const [key, value] of Object.entries(data.inputs)) {
            await form.querySelector('[name="' + key + '"]').parentNode.classList.add('error');
        }
    } else {
        form.innerHTML = successHTML;
        form.style.height = initialHeight +'px';
        dataLayer.push({'event': 'otpravka_form_feedback'});
    }

    formLoadingEnd(form);
}



const forms = document.querySelectorAll('.form-event');
forms.forEach(form => form.addEventListener('submit', handlerSubmit));