const sliders = $('.content-reviews')

sliders.each((index, slider) => {
    const sliderContainer = $(slider).find('.content-reviews__slides')
    const perRow = slider.dataset.perRow
    const prevArrow = $(slider).find('.prev')
    const nextArrow = $(slider).find('.next')
    let slidesToScroll, slidesToShow = 2
    

    let options = {
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: false,
        dotsClass: 'slider-pagination',
        appendDots: $(slider).find('.content-slider__pagination'),
        prevArrow,
        nextArrow,
        mobileFirst: true,
    }
    if (perRow == '3-per-row'){
        options.responsive = [
            {
                breakpoint: 1199,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3,
                }
            }
        ]
    }
    else if (perRow == '2-per-row'){
        const arrowsContainer = $(slider).find('.content-reviews__controls')
        const wrap = $(slider).find('.content-reviews__wrap')

        const appendArrows = () => {
            if (window.innerWidth >= 992){
                prevArrow.appendTo(wrap)
                nextArrow.appendTo(wrap)
            }
            else{
                prevArrow.appendTo(arrowsContainer)
                nextArrow.appendTo(arrowsContainer)
            }

        }
        window.addEventListener('resize', appendArrows)
        sliderContainer.on('init', appendArrows)
        options.responsive = [
            {
                breakpoint: 991,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                }
            }
        ]
    }

    sliderContainer.slick(options)
})