export default (maxValue, duration = 4500, stepTime = 35) => ({
    // duration Длительность анимации в миллисекундах
    // stepTime Интервал обновления в миллисекундах
    maxValue: String(maxValue),
    currentValue: 0,
    displayValue: 0,
    isCounting: false,
    incrementAfterMax: null,

    init() {
        this.maxValue = parseInt(this.maxValue.replace(/\D/g, ''), 10);
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !this.isCounting) {
                    this.startCounting();
                    this.isCounting = true;
                    observer.unobserve(entry.target);
                }
            });
        });
        observer.observe(this.$el);
    },

    startCounting() {
        // Убираем анимацию и сразу устанавливаем displayValue в maxValue
        this.currentValue = this.maxValue; // Устанавливаем currentValue в maxValue
        this.displayValue = this.maxValue; // Сразу выводим максимальное число

        // Закомментируем анимацию
        /*
        const totalSteps = duration / stepTime;
        const increment = this.maxValue / totalSteps;

        const updateCounter = () => {
            if (this.currentValue < this.maxValue) {
                this.currentValue += increment;
                this.displayValue = Math.floor(this.currentValue);
                requestAnimationFrame(updateCounter);
            } else {
                // Устанавливаем currentValue в maxValue
                this.currentValue = this.maxValue;
                this.displayValue = this.maxValue;
                this.startIncrementingAfterMax();
            }
        };
        
        requestAnimationFrame(updateCounter);
        */

        // Начинаем прибавление после достижения максимального значения
        this.startIncrementingAfterMax();
    },

    startIncrementingAfterMax() {
        // Увеличиваем случайным образом от 1 до 10 каждые 20000 миллисекунд (20 секунд)
        this.incrementAfterMax = setInterval(() => {
            const randomIncrement = Math.floor(Math.random() * 10) + 1; // Случайное число от 1 до 10
            this.currentValue += randomIncrement; 
            this.displayValue = Math.floor(this.currentValue); 
        }, 10000);
    },

    stopIncrementing() {
        clearInterval(this.incrementAfterMax);
        this.incrementAfterMax = null;
    }
});
