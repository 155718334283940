export async function createScrollbar(container) {
    if (!container)
        return

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = `${window.templateUrl}/static/css/simplebar.css`
    document.head.insertAdjacentElement('beforeend', link)
    const { default: SimpleBar } = await import(/* webpackChunkName: "scrollbar" */ 'simplebar')

    new SimpleBar(container, {
        autoHide: false
    })
}