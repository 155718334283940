/*
 * Фокус на input
 */
let focusInput = function() {
	Array.prototype.slice.call(document.querySelectorAll('.field')).forEach(function (elem) {
		elem.onclick = function (event) {
			let target = event.currentTarget
			target.querySelector('.field__input').focus()
		}

		Array.prototype.slice.call(elem.querySelectorAll('.field__input')).forEach(function (element) {
			checkField(element)

			element.addEventListener('focus', function () {
				element.parentNode.classList.add('active')
			})
			element.addEventListener('focusout', function () {
				checkField(element)
			})
		})
	})
}

function checkField(element) {
	if (element.value == '' || element.value == '+7 (___) ___-__-__') {
		element.parentNode.classList.remove('active')
	} else {
		element.parentNode.classList.add('active')
	}
}
focusInput()


/*     Маска телефона    */
const mask = async() => {
	const inputs = document.querySelectorAll('[type="tel"]')
  if (!inputs.length)
    return

	const smask = await import('smask')
	inputs.forEach(input => {
    smask.input(input, ['+d (ddd) ddd-dd-dd'])
  })
}
mask()

export { focusInput, mask }