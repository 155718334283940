function sectionAplication() {
    const section = document.querySelector('.section-aplication-form');
    if( !section ) return;

    const aplicationForm = section.querySelector('.aplication-form');
    const nextStepButtons = aplicationForm.querySelectorAll('.next-question');
    const prevStepButton = aplicationForm.querySelector('.aplication-form-back')
    const steps = aplicationForm.querySelectorAll('.aplication-form-step');
    const numberStep = aplicationForm.querySelector('.aplication-form-current-step');
    const input = aplicationForm.querySelector('#answers');
    
    const getActiveStep = () => document.querySelector('.aplication-form-step.active');

    const setActiveStep = (nextStep, activeStep) => {
        if( !nextStep ) return;
            const { step } = nextStep.dataset;

        prevStepButton.style.display = step != 1 ? 'flex' : 'none';

        activeStep.classList.remove('active');
        nextStep.classList.add('active');
    
        numberStep.innerText = step;
    }

    const handlerPrevStep = event => {
        event.preventDefault();
        const activeStep = getActiveStep();
        const prevStep = activeStep.previousElementSibling;

        setActiveStep(prevStep, activeStep);
    }
    
    const handlerNextStep = event => {
        const activeStep = getActiveStep();
        const nextStep = activeStep.nextElementSibling;
        
        setActiveStep(nextStep, activeStep);
    }

    prevStepButton.addEventListener('click', handlerPrevStep);
    
    nextStepButtons.forEach(button => button.addEventListener('click', handlerNextStep));
    
    aplicationForm.addEventListener('submit', event => {
        const arr = [];
        steps.forEach((step, index) => {
            if( steps.length == index + 1 ) return;
    
            const question = step.querySelector('.aplication-form-step__title').innerText;
            const answers = step.querySelectorAll('.aplication-form-step-answer__input:checked');

            let answer = '';
            answers.forEach(el => {
                if( answer != '' ) answer += ' | ';
                answer += el.value;
            });
    
            arr.push({question, answer})
        });
    
        input.value = JSON.stringify(arr);
    })
}
sectionAplication();