import { withPreventDefault } from '../../utils/withPreventDefault'
import { slideToggle } from '../../utils/collapse'

function sidebarMenu(){
    const menu = document.querySelector('.sidebar-multilevel-menu')
    if (!menu)
        return

    const onParentItemClick = (event, item) => {
        item.parentElement.classList.toggle('active')
        slideToggle(item.nextElementSibling)
    }
    const initCollapse = (item) => {
        const arrow =`
        <span class="menu-item-arrow">
            <svg>
                <use href='${window.templateUrl}/static/images/sprite.svg#arr'/>
            </svg>
        </span>
        `
        item.insertAdjacentHTML('beforeend', arrow)

        const ul = item.nextElementSibling
        ul.classList.add(...['collapse', 'is-collapsed', 'collapse-init'])
    }

    const parentItems = [...menu.querySelectorAll('.menu-item-has-children>a')]
    parentItems.forEach(item => {
        initCollapse(item)
        item.addEventListener('click', withPreventDefault(onParentItemClick, item))
    })
}
sidebarMenu()