import { delegate } from '../../utils/delegate'

function tabs(){
    delegate('click', '[data-tab-btn]', (e ,btn) => {
        const activeBtn = btn.parentElement.querySelector(`[data-tab-btn='active']`)
        const activeTab = document.querySelector(`#${activeBtn.dataset.tabTarget}`)
        activeTab.dataset.tab = ''
        activeBtn.dataset.tabBtn = ''

        btn.dataset.tabBtn = 'active'
        const newTab = document.querySelector(`#${btn.dataset.tabTarget}`)
        newTab.dataset.tab = 'active'
    })
}
tabs()