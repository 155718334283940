const forms = document.querySelectorAll('.form-event');
const html = `
	<div class="loading-animate-wrap">
		<div class="loading-animate">
			<div class="loading-animate__sqr loading-animate__sqr--1"></div>
			<div class="loading-animate__sqr loading-animate__sqr--2"></div>
			<div class="loading-animate__sqr loading-animate__sqr--3"></div>
		</div>
	</div>
`;


forms.forEach(form => {
	form.insertAdjacentHTML('afterbegin', html);
});