const $sliderWrap = $('.banner-slider-wrapper');
const $slider = $sliderWrap.find('.banner-slider')

window.addEventListener('load', () => {
    $slider.slick({
        autoplay: $slider.data('autoplay'), 
        autoplaySpeed: $slider.data('autoplaySpeed'),
        speed: $slider.data('speed'),
        pauseOnHover: false,
        fade: true,
        dots: true,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: $sliderWrap.find('.banner-slider-controls__arrow.prev'),
        nextArrow: $sliderWrap.find('.banner-slider-controls__arrow.next'),
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    })
})