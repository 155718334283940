const sliders = $('.content-slider')

sliders.each((index, slider) => {
    const sliderContainer = $(slider).find('.content-slider__slides')
    sliderContainer.slick({
        dots: true,
        dotsClass: 'slider-pagination',
        appendDots: $(slider).find('.content-slider__pagination'),
        prevArrow: $(slider).find('.prev'),
        nextArrow: $(slider).find('.next')
    })
})