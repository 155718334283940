const responsiveTable = () => {
  const tables = document.querySelectorAll('.content-text table')
  if (!tables.length)
    return

  const wrap = (table) => {
    const div = document.createElement('div')
    div.className = 'table-responsive table-wrap'
    table.insertAdjacentElement('afterend', div)
    div.insertAdjacentElement('afterbegin', table)
  }
  tables.forEach(wrap)
}
responsiveTable()