import { startWatch } from '../../utils/lazy-load'

export default class Ymap {
	constructor(map) {
		this.details = {
			map: map,
			markers: [],
			zoom: 17,
			marker: {
				icon: map.dataset.markerImg,
				height: map.dataset.markerHeight,
				width: map.dataset.markerWidth
			}
		}
		map.querySelectorAll('.markers').forEach(el => {
			this.details.markers.push( [parseFloat(el.dataset.lat), parseFloat(el.dataset.lng)] );
		});

		const result = Ymap.loadAPI()
		if (result === true)
			this.init();
		else
			result.then(() => this.init())
	}

	static ymapIsReady = false
	static loaderPromise = false

	static loadAPI(){
		if (Ymap.ymapIsReady === true)
			return true
			
		if (Ymap.loaderPromise !== false)
			return Ymap.loaderPromise

		Ymap.loaderPromise = new Promise(resolve => {
			const script = document.createElement('script')
			script.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU`
			script.addEventListener('load', () => {
				ymaps.ready(() => {
					Ymap.ymapIsReady = true
					resolve()
				})
			})
			document.body.append(script)
		})
		return Ymap.loaderPromise
	}

	init() {
		this.ymap = new ymaps.Map(this.details.map.id, {
			center: [this.details.markers[0][0], this.details.markers[0][1]],
			zoom: this.details.zoom,
			controls: []
		})
		this.objectManager = new ymaps.ObjectManager({
			clusterize: true
		});
	
		this.objectManager.objects.options.set({
			iconLayout: 'default#image',
			iconImageHref: this.details.marker.icon,
			iconImageSize: [this.details.marker.width, this.details.marker.height],
			iconImageOffset: [(this.details.marker.width / 2 * -1), (this.details.marker.height * -1)],
			openBalloonOnClick: false
		});
		this.objectManager.clusters.options.set({
			preset: 'islands#invertedRedClusterIcons',
			openBalloonOnClick: false
		});
	
		this.ymap.geoObjects.add(this.objectManager);

		this.addMarkers();
		
		this.collapse();
	}

	addMarkers() {
		this.objectManager.removeAll();

		let myPlacemarks = {
			type: "FeatureCollection",
			features: []
		};
		this.details.markers.forEach((coordinate, index) => {
			myPlacemarks.features.push({
				type: "Feature",
				id: index,
				geometry: {
					type: "Point",
					coordinates: [coordinate[0], coordinate[1]]
				}
			});
		})

		this.objectManager.add(myPlacemarks);

		this.centerMap();
		this.ymap.container.fitToViewport();
	}

	centerMap() {
		this.ymap.setBounds(this.ymap.geoObjects.getBounds());
		if( this.details.markers.length == 1 ) this.ymap.setZoom( 17 );
	}

	reInitMap(markers) {
		if( !markers.length ) return;


		this.details.markers = markers;

		this.addMarkers()

		return true;
	}
	
	collapse() {
		const collapses = document.querySelectorAll('.collapse');
	
		const resize = () => {
			this.setMapHeight();
			this.ymap.container.fitToViewport();
		}

		collapses.forEach(collapse => {
			const header = collapse.querySelector('.collapse__header');
			const body = collapse.querySelector('.collapse__body');

			const handlerClick = event => {
				event.preventDefault();
	
				if( collapse.classList.contains('active') ) {
					collapse.classList.remove('active');
					$(body).stop().slideUp('fast', resize);
				} else {
					collapses.forEach(el =>  {
						const body = el.querySelector('.collapse__body');
						$(body).stop().slideUp('fast', resize);
						el.classList.remove('active');
					});
	
					collapse.classList.add('active');
					$(body).stop().slideDown('fast', resize);

					this.reInitMap([[header.dataset.lat, header.dataset.lng]]);
				}
			}
	
			header.removeEventListener('click', handlerClick);
			header.addEventListener('click', handlerClick);
		});
	}

	setMapHeight() {
		const sectionLeft = this.details.map.closest('.section-contacts__wrap').querySelector('.section-contacts-left-block-wrap');
		this.details.map.style.height = sectionLeft.clientHeight+'px';
	}

	setCenter(center,zoom = 15){
		if (this.ymap){
			this.ymap.setCenter(center,zoom)
		}
	}
}

window.addEventListener('load', () => {
	const maps = document.querySelectorAll('.map');
	if (maps.length == 0)
		return

	const init = map => new Ymap(map)

	startWatch([...maps], (mapContainer, isVisible) => {
		if (!isVisible)
			return

		const result = Ymap.loadAPI()
		init(mapContainer)
	})
})