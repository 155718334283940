import { delegate } from '../../utils/delegate'

function amount() {

	delegate('click', '.amount__button', (e, btn) => {
		e.preventDefault()

		const input = btn.parentNode.querySelector('.amount__input')

		if( btn.classList.contains('plus') ) {
			if( input.value >= input.max ) return
			input.value++;
		} else {
			if( input.value <= input.min ) return
			input.value--;
		}

		input.dispatchEvent(new CustomEvent("changeValue"));
	})

	delegate('change', '.amount__input', (e, input) => {
		const val = parseInt(input.value)
		if( Number.isInteger( val ) ) {
			if( val > target.max ) {
				target.value = target.max;
			} else if( val < target.min ) {
				target.value = target.min;
			} else {
				target.value = target.value;
			}
		}

		input.dispatchEvent(new CustomEvent("changeValue"));
	})
}
amount()