function initGallery() {
    const gallery = document.querySelector('[data-fancybox]')
    if (!gallery)
        return

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = `${window.templateUrl}/static/css/fancybox.css`
    document.head.insertAdjacentElement('beforeend', link)
    import(/* webpackChunkName: "gallery" */ '@fancyapps/fancybox/dist/jquery.fancybox.min.js')
}

window.addEventListener('load', initGallery)