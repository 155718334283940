import { focusInput, mask } from '../common/field';
import { delegate } from '../../utils/delegate'

const formLoading = parent => parent.classList.add('loading');
const formLoadingEnd = parent => parent.classList.remove('loading')

function modalOrderForm() {
    const modal = document.getElementById('modal-service');
    const form = modal.querySelector('.form-service-order');
    const successHTML = `<div class="form-success"><svg role="image" class="form-success__svg"><use xlink:href="${ templateUrl }/static/images/sprite.svg#success" /></svg><p class="form-success__text">Сообщение успешно отправленно</p></div>`;
    
    let service = null;

    modal.addEventListener('modal_open', event => {
        event.preventDefault();
        const serviceName = event.detail.target.dataset.serviceName;
        service = serviceName
    })


    const handlerFormSubmit = async event => {
        event.preventDefault();
        const initialHTML = form.innerHTML;
        const initialHeight = form.clientHeight;

        const formData = new FormData(form);
        formData.append('action', form.dataset.action);
        if( service ) formData.append('service', service);
        
        

        formLoading(form);
        form.querySelectorAll('.field').forEach(el => el.classList.remove('error'));
        const data  = await fetch('feadback/ajax.php', { method: 'POST', body: formData});
        console.log( data )

        if( !data || data.valid == 'not-valid' ) {
            console.error( 'not valid' )
            if( data.inputs ) {
                for(const [key, value] of Object.entries(data.inputs)) {
                    await form.querySelector('[name="' + key + '"]').parentNode.classList.add('error');
                }
            }
        } else {
            console.log( 'success' )
            form.innerHTML = successHTML;
            form.style.height = initialHeight +'px';
            dataLayer.push({'event': 'otpravka_form_service'});
    
            setTimeout(() => {
                form.innerHTML = initialHTML;
                form.removeAttribute('style');
    
                // resetForm(form);
                focusInput();
                mask();
            }, 3000);
        }
    
        formLoadingEnd(form);
    }

    form.addEventListener('submit', handlerFormSubmit);
}
modalOrderForm()

function tabs() {
    const section = document.querySelector('.section-service');
    if (!section)
        return

    const serviceBlockWrap = section.querySelector('.service-block')
    if (!serviceBlockWrap)
        return

    const title = serviceBlockWrap.querySelector('.service-block__title')
    const list = serviceBlockWrap.querySelector('.service-block-insludes')
    const btn = serviceBlockWrap.querySelector('.button-orang')
    const btnText = serviceBlockWrap.querySelector('.button-orang__text')
    const img = serviceBlockWrap.querySelector('.service-block__img')

    const onCardClick = (e ,card) => {
        const data = JSON.parse(card.dataset.serviceInfo)
        console.log(data)
        const activeCard = section.querySelector('.card-service-type-three.active')
        activeCard.classList.remove('active')
        card.classList.add('active')

        title.textContent = data.title
        if (data.btn){
            btnText.textContent = data.btn
            btn.dataset.serviceName = data.title
        }
        else{
            btnText.textContent = "Заказать услугу"
            btn.dataset.serviceName = "Заказать услугу"
        }

        if (data.img){
            img.classList.remove('hidden')
            img.src = data.img
        }
        else
            img.classList.add('hidden')

        list.innerHTML = ''
        if (data.includes){
            list.classList.remove('hidden')
            data.includes.forEach(include => list.insertAdjacentHTML('beforeend', `<p class="service-block-insludes__item">${include}</p>`))
        }
        else
            list.classList.add('hidden')
    }

    delegate('click', '.card-service-type-three', onCardClick, section)
}

tabs()