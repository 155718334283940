import { startWatch } from '../../utils/lazy-load'

class Schema {
	constructor(element) {
		if (!element || this.isMobile())
			return

		this.schema		= element;
		this.line       = element.querySelector('.schema-line');
		this.lineFilling = this.line.querySelector('.schema-line__filling');

		this.init();
	}

	init() {
		const handler = (pixel, isVisible) => {
			const step = pixel.closest('.schema-step, .schema-last-step')
			isVisible ? this.animationShow(step) : this.animationHide(step)
		}
		const pixels = this.schema.querySelectorAll('.schema-step__watch-pixel')
		startWatch(pixels, handler, '500px 0px 0px', 0, false)
		this.fillingLine()
	}
    
    animationHide = elem => elem.classList.remove('show')
	animationShow = elem => elem.classList.add('show')

	fillingLine = () => {
		const setTranslate = translate => this.lineFilling.style.setProperty('transform', `translateY(${translate})`)

		const lineHandler = (line, isIntersecting, entry) => {
			if (!isIntersecting){
				setTranslate('0px')
				return
			}

			const rect = entry.boundingClientRect
			const top = rect.top - window.innerHeight * (6 / 7)

			if(entry.intersectionRatio > 0.97)
				setTranslate('100%')
			else if(top < 0 )
				setTranslate(`${top * -1}px`)
			else
				setTranslate('0px')
		}
		startWatch([this.line], lineHandler, '10% 0% -15% 0%', [...Array(50).keys()].map(x => x / 50), false )
	}

	isMobile = () => window.innerWidth < 992;
}

new Schema(document.querySelector('.schema'));