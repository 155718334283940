import { delegate } from '../../utils/delegate'

function initModal(){
    delegate('click', '[data-modal]', (e, btn) => {
        e.preventDefault()
        modalOpen(btn.dataset.modal, btn)
    })
    delegate('click', '.close-modal-event', (e, btn) => modalClose())
}
initModal()

export default function modalOpen(modalID, target = null) {
    const modal = document.querySelector(`#${modalID}`);

    modal.dispatchEvent(new CustomEvent("modal_open", {
        detail: {target}
    }));
    
    modal.classList.add('modal-open');
    document.body.classList.add('opened');
}

function modalClose() {
    const modal = document.querySelector('.custom-modal.modal-open');
    modal.classList.add('modal-closing')
    modal.addEventListener('animationend', () => {
        document.body.classList.remove('opened')
        modal.classList.remove('modal-closing')
        document.body.removeAttribute('style')
        modal.classList.remove('modal-open')
        const modalContent = modal.querySelectorAll('.custom-modal__content');
        modalContent.forEach(el => el.removeAttribute('style'))
        
        const iframe = modal.querySelector('.iframe-modal');
        if( iframe )
            iframe.src = ''
    }, {once: true})

    modal.dispatchEvent(new CustomEvent("modal_close"));
}