window.addEventListener('load', () => {
    const $sliderWrap = $('.reviews-slider-wrap')
    const $slider = $sliderWrap.find('.reviews-slider');

    let perSlide = 1;
    let responsive = [];
    if( $slider.hasClass('reviews-slider--one') ) {
        perSlide = 2;
        responsive = [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ];
    } else if( $slider.hasClass('reviews-slider--two') ) {
        perSlide = 1;
    } else if( $slider.hasClass('reviews-slider--three') ) {
        perSlide = 3;
        responsive = [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ];
    } else if( $slider.hasClass('reviews-slider--four') ) {
        perSlide = 1;
    } else if( $slider.hasClass('reviews-slider--five') ) {
        perSlide = 1;
    }

    $slider.slick({
        infinite: false,
        speed: 700,
        slidesToScroll: perSlide,
        slidesToShow: perSlide,
        prevArrow: $sliderWrap.find('.reviews-slider-arrows__arrow.prev'),
        nextArrow: $sliderWrap.find('.reviews-slider-arrows__arrow.next'),
        dots: true,
        adaptiveHeight: true,
        responsive
    });
})



function media() {
    const media = document.querySelectorAll('.media');

    const handlerClick = event => {
        event.preventDefault();
        const { target } = event;
        const parent = target.closest('.media');
        const iframe = parent.querySelector('.media__iframe');

        parent.classList.add('played');
        iframe.src = iframe.dataset.src;
    }

    media.forEach(el => {
        const playButton = el.querySelector('.video-play__button');

        playButton.addEventListener('click', handlerClick);
    })
}
media()