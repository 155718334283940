import Ymap from './section-contacts'
import {delegate} from '../../utils/delegate'


function createMap(){
    const map = document.querySelector('#contacts-map')
    if (!map)
        return false

    const mapObj = new Ymap(map)
    delegate('click', '.address-block__on-map', (event, btn) => mapObj.setCenter([btn.dataset.lat, btn.dataset.lng]))
}

window.addEventListener('load', createMap)