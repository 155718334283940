import { printPage } from '../../utils/print'

const section = document.querySelector('.section-requisites');

function printRequisites() {
    if (!section)
        return

    const button = section.querySelector('.requisites__button');
    const contentPageID = button.dataset.contentPageId
    button.addEventListener('click', () => printPage(`/wp-admin/admin-ajax.php?action=print_requisites&page_id=${contentPageID}`));
}
printRequisites();

function copyRequisites() {
    if( !section ) return;

    const getCopyText = () => {
        const copyBlocks = [...section.querySelectorAll('.requisite')]
        const res = copyBlocks.reduce( (text, currentBlock) => {
            const key = currentBlock.querySelector('.requisite__left-left').innerText;
            const value = currentBlock.querySelector('.requisite__right').innerText;

            return text += `${key} ${value}\n`
        }, '' )

        return res
    }

    const handlerCopy = event => {
        event.preventDefault()
        event.clipboardData.setData("text/plain", getCopyText().trim())
    }


    const button = section.querySelector('.section-requisites-copy');
    button.addEventListener('click', () => document.execCommand("copy"));
    button.addEventListener('copy', handlerCopy);
}
copyRequisites();