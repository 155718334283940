export function delegate(eventName, elementSelector, handler, listener=document){
	function eventCallback(e) {
		for (let target = e.target; target && target != this; target = target.parentNode) {
			if (target.matches(elementSelector)) {
				handler.call(target, e, target);
				break;
			}
		}
	}

	listener.addEventListener(eventName, eventCallback)

	return () => listener.removeEventListener(eventName, eventCallback)
}