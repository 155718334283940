import { focusInput, mask } from '../common/field';
import axios from 'axios';


const formLoading = parent => {
	parent.classList.add('loading');
}

const formLoadingEnd = parent => {
	parent.classList.remove('loading');
}


function modalOrderForm() {
    const modal = document.getElementById('modal-FAQ');
    const form = modal.querySelector('.form-FAQ');
    const successHTML = `<div class="form-success"><svg role="image" class="form-success__svg"><use xlink:href="${ templateUrl }/static/images/sprite.svg#success" /></svg><p class="form-success__text">Сообщение успешно отправленно</p></div>`;
    
    let price = null;
    let productName = null;

    const handlerFormSubmit = async event => {
        event.preventDefault();
        const initialHTML = form.innerHTML;
        const initialHeight = form.clientHeight;

        const formData = new FormData(form);
        formData.append('action', form.dataset.action);
        if( price ) formData.append('price', price);
        if( productName ) formData.append('product-name', productName);
        

        formLoading(form);
        form.querySelectorAll('.field').forEach(el => el.classList.remove('error'));
        const data  = await fetch('feadback/ajax.php', { method: 'POST', body: formData});

        if( !data || data.valid == 'not-valid' ) {
            console.error( 'not valid' )
            for(const [key, value] of Object.entries(data.inputs)) {
                await form.querySelector('[name="' + key + '"]').parentNode.classList.add('error');
            }
        } else {
            console.log( 'success' )
            form.innerHTML = successHTML;
            form.style.height = initialHeight +'px';
            dataLayer.push({'event': 'otpravka_form_FAQ'});
    
            setTimeout(() => {
                form.innerHTML = initialHTML;
                form.removeAttribute('style');
    
                // resetForm(form);
                focusInput();
                mask();
            }, 3000);
        }
    
        formLoadingEnd(form);
    }

    form.removeEventListener('submit', handlerFormSubmit);
    form.addEventListener('submit', handlerFormSubmit);
}

modalOrderForm();