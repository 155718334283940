export function lazyLoad(element){
	if (element.nodeName == "PICTURE"){
		const sources = element.querySelectorAll('[data-srcset]')
		sources.forEach(source => source.srcset = source.dataset.srcset)

		const img = element.querySelector('[data-src]')
		img.src = img.dataset.src

		return
	}

	if (element.dataset.src)
		element.src=element.dataset.src
	if (element.dataset.bg)
		element.style.backgroundImage=`url('${element.dataset.bg}')`
}


export function startWatch(elements, handler, margin="35% 0% 35%", threshold=0, unobserve = true){
	if (!elements)
		return false

	const callback = (entries, observer) => {
		entries.forEach(entry => {
			handler(entry.target, entry.isIntersecting, entry)
			if(entry.isIntersecting && unobserve)
				observer.unobserve(entry.target)
		})
	}

	const observer = new IntersectionObserver(callback, {rootMargin: margin, threshold: threshold });
	elements.forEach(elem => observer.observe(elem))
}