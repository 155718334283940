import "slick-carousel";

// import './jquery-shim'

import './alpine';

import './components/common/amount';
import './components/common/modal';
// import './components/common/mobile-menu';
import './components/common/field';
import './components/common/custom-timer';
import './components/common/addToRequest';
import './components/common/preloader';
import './components/common/collapse';
import './components/common/toTop';
import './components/common/scrollbar';
import './components/common/play-video';
import './components/common/tabs';
import './components/common/img-lazy-load';
import './components/common/responsiveTable';


import './components/sections/section-banner-slider';
import './components/sections/section-aplication-form';
import './components/sections/section-slider';
import './components/sections/section-service';
import './components/sections/init-catalog';
import './components/sections/section-advantages';
import './components/sections/section-gallery';
import './components/sections/section-contacts';
import './components/sections/section-tariff';
import './components/sections/section-schema';
import './components/sections/section-partners';
import './components/sections/section-employees';
import './components/sections/section-review';
import './components/sections/section-faq';
import './components/sections/section-requisites';
import './components/sections/section-about-company';
import './components/sections/contacts-map';
import './components/sections/content-slider';
import './components/sections/content-reviews-slider';
import './components/sections/sidebar-menu';
import './components/sections/gallery';



import './components/common/feadback';
