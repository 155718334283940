import "./modal-alpine.scss"

export default (initialState = false) => ({
    isOpen: initialState,
    // content_id: content_id,
    init() {
        this.$watch('isOpen', (isOpen) => {
            // isOpen ? document.documentElement.classList.add('overflow-hidden') : document.documentElement.classList.remove('overflow-hidden')
            document.documentElement.style.overflow = isOpen ? 'hidden' : '';
        })
    },
    close() {
        this.isOpen = false
    },
    open() {
        this.isOpen = true
    },
    root: {
        ['x-ref']: 'root',
        ['x-transition:enter']: 'modal-alpine--enter',
        ['x-transition:enter-start']: 'modal-alpine--enter-start',
        ['x-transition:enter-end']: 'modal-alpine--enter-end',
        ['x-transition:leave']: 'modal-alpine--leave',
        ['x-transition:leave-start']: 'modal-alpine--leave-start',
        ['x-transition:leave-end']: 'modal-alpine--leave-end',
        ['x-modelable']: 'isOpen',
        ['x-show']: 'isOpen',
        ['@keyup.escape.window']: 'close()',
        ['x-cloak']: '',
    },
    overlay: {
        ['@click.self']: 'close()'
    },
    hide: {
        ['@click']: 'close()'
    }
})