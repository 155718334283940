function closePrint () {
    document.body.removeChild(this.__container__);
}

function setPrint(){
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = closePrint;
    this.contentWindow.onafterprint = closePrint;
    this.contentWindow.focus(); // Required for IE
    this.contentWindow.print();
}

export function printPage(url) {
    const iframe = document.createElement("iframe");
    iframe.classList.add('printing-iframe')
    
    iframe.onload = setPrint
    iframe.src = url
    document.body.insertAdjacentElement('afterbegin', iframe)
}