window.addEventListener('load', () => {
    const $slider = $('.section-employees');
    
    $slider.find('.employees-slider').slick({
        infinite: false,
        slidesToShow: 1,
        prevArrow: $slider.find('.employees-slider__arrow.prev'),
        nextArrow: $slider.find('.employees-slider__arrow.next'),
        dots: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });
})