const timers = document.querySelectorAll('.custom-timer');

timers.forEach(timer => {
    let $remaining = timer.querySelector('.storage-for-remaining').value;
    if( $remaining <= 0 ) return;

    remaining(timer, $remaining);
    setInterval(() => {
        remaining(timer, $remaining);
        $remaining--;
    }, 1000);
});

    
function remaining(timer, strtotime) {
    let remainingDay = Math.floor(strtotime / 86400);
    let remainingHours = Math.floor((strtotime % 86400) / 3600);
    let remainingMinutes = Math.floor(((strtotime % 86400) % 3600) / 60);
    let remainingSeconds = Math.floor(((strtotime % 86400) % 3600) % 60);
    let day = timer.querySelector('.custom-timer__int--day');
    let hours = timer.querySelector('.custom-timer__int--hours');
    let minutes = timer.querySelector('.custom-timer__int--minutes');
    let seconds = timer.querySelector('.custom-timer__int--seconds');

    if( remainingDay < 10 ) remainingDay = '0' + remainingDay;
    if( remainingHours < 10 ) remainingHours = '0' + remainingHours;
    if( remainingMinutes < 10 ) remainingMinutes = '0' + remainingMinutes;
    if( remainingSeconds < 10 ) remainingSeconds = '0' + remainingSeconds;

    if( day.innerText !== remainingDay ) day.innerHTML = remainingDay.toString().replace(/(.)/g, '<span class="timer-int">$1</span>');
    if( hours.innerText !== remainingHours ) hours.innerHTML = remainingHours.toString().replace(/(.)/g, '<span class="timer-int">$1</span>');
    if( minutes.innerText !== remainingMinutes ) minutes.innerHTML = remainingMinutes.toString().replace(/(.)/g, '<span class="timer-int">$1</span>');
    if( seconds.innerText !== remainingSeconds ) seconds.innerHTML = remainingSeconds.toString().replace(/(.)/g, '<span class="timer-int">$1</span>');
}