const collapses = document.querySelectorAll('.cc');

if( collapses.length ) {

	collapses.forEach(collapse => {
		const header = collapse.querySelector('.cc-h')
		const body = collapse.querySelector('.cc-b')

		header.addEventListener('click', event => {
			event.preventDefault();
			collapse.classList.toggle('active');
			$(body).stop().slideToggle();
		})
	});

};