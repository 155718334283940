window.addEventListener('load', () => {
    const $slider = $('.gallery-slider-wrap');
    
    $slider.find('.gallery-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $slider.find('.gallery-slider-arrows__arrow.prev'),
        nextArrow: $slider.find('.gallery-slider-arrows__arrow.next'),
        dots: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            }
        ]
    })
})



function galleryCollapse() {
    const blocks = document.querySelectorAll('.gallery-collapse');

    blocks.forEach(block => {
        const button = block.querySelector('.gallery-show-more');
        let buttonText = button.querySelector('.gallery-show-more__text');
        const hiddenBlock = block.querySelector('.gallery-hidden-block');

        const toggleCollapse = event => {
            event.preventDefault();

            button.classList.toggle('active');
            $(hiddenBlock).stop().slideToggle('fast');

            buttonText.innerText = button.classList.contains('active') ? 'Скрыть' : 'Показать еще';
        }

        button.addEventListener('click', toggleCollapse);
    });
}
galleryCollapse();