import { focusInput, mask } from '../common/field';
import axios from 'axios';


const formLoading = parent => {
	parent.classList.add('loading');
}

const formLoadingEnd = parent => {
	parent.classList.remove('loading');
}


function modalOrderForm() {
    const modal = document.getElementById('modal-tariff');
    const form = modal.querySelector('.form-tariff-order');
    const successHTML = `<div class="form-success"><svg role="image" class="form-success__svg"><use xlink:href="${ templateUrl }/static/images/sprite.svg#success" /></svg><p class="form-success__text">Сообщение успешно отправленно</p></div>`;
    
    let price = null;
    let productName = null;

    modal.addEventListener('modal_open', event => {
        event.preventDefault();
        const { target } = event.detail;
        const card = target.closest('.tariff');

        price = card.querySelector('.tariff__price').innerText;
        productName = card.querySelector('.tariff__name').innerText;
    })


    const handlerFormSubmit = async event => {
        event.preventDefault();
        const initialHTML = form.innerHTML;
        const initialHeight = form.clientHeight;

        const formData = new FormData(form);
        formData.append('action', form.dataset.action);
        if( price ) formData.append('price', price);
        if( productName ) formData.append('product-name', productName);
        

        formLoading(form);
        form.querySelectorAll('.field').forEach(el => el.classList.remove('error'));
        const data  = await fetch('feadback/ajax.php', { method: 'POST', body: formData});

        if( !data || data.valid == 'not-valid' ) {
            console.error( 'not valid' )
            for(const [key, value] of Object.entries(data.inputs)) {
                await form.querySelector('[name="' + key + '"]').parentNode.classList.add('error');
            }
        } else {
            console.log( 'success' )
            form.innerHTML = successHTML;
            form.style.height = initialHeight +'px';
            dataLayer.push({'event': 'otpravka_form_tariff'});
    
            setTimeout(() => {
                form.innerHTML = initialHTML;
                form.removeAttribute('style');
    
                // resetForm(form);
                focusInput();
                mask();
            }, 3000);
        }
    
        formLoadingEnd(form);
    }

    form.removeEventListener('submit', handlerFormSubmit);
    form.addEventListener('submit', handlerFormSubmit);
}

modalOrderForm();


// Смотреть больше
(function() {
    let btn = document.querySelector('.section-tariffs [data-show]');
    if ( !btn ) return false;

    let btnText = document.querySelector('.section-tariffs [data-show] span');
    let $hiddenTariffs = $('.section-tariffs .tariff--hidden');
    btn.addEventListener('click', function(e) {
        e.preventDefault;
        if (btn.classList.contains('shown')) {
            $hiddenTariffs.slideUp({
                start: function() {
                    btn.classList.remove('shown');
                },
                done: function() {
                    btnText.textContent = 'Показать ещё';
                }
            });
        }
        else {
            $hiddenTariffs.slideDown({
                start: function() {
                    btn.classList.add('shown');
                    $(this).css('display','grid');
                },
                done: function() {
                    btnText.textContent = 'Скрыть';
                }
            });
            
        }
    })
})();


(function() {
    $(document).ready(function(){
        let $tariffs = $('.section-tariffs--four .tariffs');
        if ($tariffs.length === 0) return;
        $tariffs.slick({
            dots: true,
            // infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            prevArrow: document.querySelector('.tariffs-slider-arrows__arrow.prev'),
            nextArrow: document.querySelector('.tariffs-slider-arrows__arrow.next'),
            responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
      });
})();


