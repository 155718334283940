// import "@fontsource-variable/montserrat";
import "./scss/main.scss";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";

// import "./js/components/feadback";
// import "./js/components/index";
// import "./js/components/slider";
// import "./js/components/mask";

// import "./js/components/modal";
// import "./js/components/loading";

import "./js";

// window.addEventListener("load", () => {
//     import("htmx.org").then(htmx => {
//         htmx.config.scrollIntoViewOnBoost = false
//     })
// })
