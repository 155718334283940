import { createScrollbar } from '../common/scrollbar'

const elems = document.querySelectorAll('.my-simplebar');
const advantages = document.querySelector('.advantages');

elems.forEach(async (elem) => {
    await createScrollbar(elem);
    const sbContentWrapper = elem.querySelector('.simplebar-content-wrapper');

    // Прокрутка перетаскиванием
    advantages.addEventListener('mousedown', (evt) => {
        let mouseMovePrevPosX = evt.pageX;

        const onAdvantagesMousemove = (evt) => {
            const mouseMoveCurPosX = evt.pageX;
            const diffCursorPosX = mouseMoveCurPosX - mouseMovePrevPosX;

            sbContentWrapper.scrollLeft += diffCursorPosX;
            mouseMovePrevPosX = mouseMoveCurPosX;
        };
        
        advantages.addEventListener('mousemove', onAdvantagesMousemove);

        const removeEventHadler = () => {
            advantages.removeEventListener('mousemove', onAdvantagesMousemove);

            advantages.removeEventListener('mouseup', removeEventHadler);
            advantages.removeEventListener('mouseleave', removeEventHadler);
        };

        advantages.addEventListener('mouseup', removeEventHadler);
        advantages.addEventListener('mouseleave', removeEventHadler);
    });
});