

import Alpine from "alpinejs"
import intersect from '@alpinejs/intersect'
window.Alpine = Alpine
Alpine.plugin(intersect)

import CounterNumber from './CounterNumber'
Alpine.data('CounterNumber', CounterNumber)

import ComponentModal from './Modal/ComponentModal'
Alpine.data('Modal', ComponentModal)

Alpine.store('mainMenu', new Proxy({
    open: false,
}, {
    set(target, property, value) {
        if (property === 'open')
            document.documentElement.style.overflow = value ? 'hidden' : '';
        target[property] = value;
        return true;
    }
}));
Alpine.start()